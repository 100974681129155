import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { AppToaster } from "./AppToaster";
import { AppTopbar } from "./topbar/AppTopbar";
import { Box } from "@twilio-paste/core/box";
import { OutletWrapper } from "./OutletWrapper";
import { SidebarWrapper } from "./sidebar/SidebarWrapper";
import { useAppContext } from "../hooks/useAppContext";
import { useEffect } from "react";
import { useUID } from "@twilio-paste/core/uid-library";

export const ConversationApp = () => {
	const { isAuthenticated } = useAppContext();
	const mainContentId = useUID();
	const topbarId = useUID();
	const [urlParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		if(!isAuthenticated) {
			navigate(`/auth?redirectUri=${window.location.pathname}&${urlParams.toString()}`);
		}
	}, [ isAuthenticated ]);

	return (<SidebarWrapper mainContentID={mainContentId} topbarID={topbarId}>
		<Box display="flex" flexDirection="column" minHeight="100vh" overflow="hidden">
			<AppToaster />
			<AppTopbar id={topbarId} />
			<OutletWrapper id={mainContentId}>
				<Outlet />
			</OutletWrapper>
		</Box>
	</SidebarWrapper>)
}