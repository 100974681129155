import { AppHelmet } from "./components/AppHelmet";
import { AppProvider } from "./providers/AppProvider/AppProvider";
import { Box } from "@twilio-paste/core/box";
import { ConversationProvider } from "./providers/ConversationProvider/ConversationProvider";
import { Outlet } from "react-router-dom";

export const App = () => {
	return (
		<AppProvider>
			<ConversationProvider>
				<Box overflow="hidden">
					<AppHelmet />
					<Outlet />
				</Box>
			</ConversationProvider>
		</AppProvider>)
}