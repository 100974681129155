import { Conversation, DfaMoneypennyClient } from "@ciptex/dfa-moneypenny-sdk";
import { useCallback, useEffect, useState } from "react";
import { Auth } from "../AppProvider";
import { useIframeMessage } from "../../../hooks/useIframeMessage";
import { useNavigate } from "react-router-dom";

export interface useApiStateHook {
	conversations: Conversation[] | undefined;
	createConversation: () => Promise<Conversation>;
	archiveConversation: (conversationSid: string) => Promise<void>;
	isLoading: boolean;
}

export const useApi = ({ auth }: { auth?: Auth }): useApiStateHook => {
	const [conversations, setConversations] = useState<Conversation[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const navigate = useNavigate();
	const { PostMessageToParent } = useIframeMessage();

	const createConversation = useCallback(async () => {
		if (!auth) {
			throw new Error("No Authentication Token");
		}

		const client = new DfaMoneypennyClient({ token: auth.token });
		const newConversation = await client.conversation.create(
			{
				identity: auth.identity
			},
			{ configSid: auth.configSid }
		);

		PostMessageToParent({
			type: "ConversationCreated",
			data: {
				conversationSid: newConversation.conversationSid
			}
		});

		setConversations([...conversations, newConversation]);

		return newConversation;
	}, [conversations, auth]);

	const archiveConversation = useCallback(async (conversationSid: string) => {
		if (!auth) {
			throw new Error("No Authentication Token");
		}

		const client = new DfaMoneypennyClient({ token: auth.token });
		await client.conversation.update(
			{
				identity: auth.identity,
				state: "closed"
			},
			{ configSid: auth.configSid, conversationSid: conversationSid }
		);

		setConversations(conversations.filter((x) => x.conversationSid !== conversationSid));
	}, [conversations, auth]);

	const listConversations = useCallback(async () => {
		if (!auth) {
			return;
		}

		try {
			setIsLoading(true);
			const client = new DfaMoneypennyClient({ token: auth.token });
			const data = await client.conversation.list({
				configSid: auth.configSid,
				identity: auth.identity
			});
			setConversations(data);
		} catch (error: any) {
			if(error.status === 401) {
				sessionStorage.removeItem("t");
				navigate("/auth");
			}
		}

		setIsLoading(false);
	}, [auth]);

	useEffect(() => {
		listConversations();
	}, [listConversations]);

	return { conversations, createConversation, archiveConversation, isLoading };
};