import { PublicAppConfig, STATIC_CONFIG } from "../../../constants";
import { useCallback, useEffect, useState } from "react";
import { DfaMoneypennyClient } from "@ciptex/dfa-moneypenny-sdk";

export const useConfig = () => {
	const [config, setConfig] = useState<PublicAppConfig>(STATIC_CONFIG);

	const getConfig = useCallback(async () => {
		try {
			const client = new DfaMoneypennyClient();
			const c = await client.config.fetch1({
				accountSid: STATIC_CONFIG.accountSid,
				configSid: STATIC_CONFIG.configSid
			});
			setConfig({ ...STATIC_CONFIG, ...c });
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		getConfig();
	}, [getConfig])

	return { config };
}