import { Button } from "@twilio-paste/core/button";
import { ThumbsUpIcon } from "@twilio-paste/icons/esm/ThumbsUpIcon";
import { useCallback } from "react"


export const LikeButton = ({ rating, onClick }: { rating?: -1 | 0 | 1, onClick: (rating: -1 | 0 | 1) => Promise<void> }) => {
	const onClickL = useCallback(() => {
		if(rating === 1) {
			onClick && onClick(0)
		} else {
			onClick && onClick(1)
		}
	}, [ rating, onClick ]);

	return (<Button
		variant={rating === 1 ? "primary_icon" : "secondary_icon"}
		size="reset"
		onClick={onClickL}
	>
		<ThumbsUpIcon decorative={false} title="Like" />
	</Button>);
}