import { Client, ConnectionState, State } from "@twilio/conversations";
import { useCallback, useEffect, useState } from "react";

export interface useClientEventsStateHook {
	client: Client | undefined;
	clientState: State | null;
	clientConnectionState: ConnectionState;
}

export const useClientEvents = ({ client }: { client?: Client }): useClientEventsStateHook => {
	const [clientState, setClientState] = useState<State | null>(null);
	const [clientConnectionState, setConnectionState] = useState<ConnectionState>("disconnected");

	const onTokenAboutToExpire = useCallback(async (): Promise<void> => {
		// token is about to expire. get a new token
		try {
			const token = "";
			client = await client?.updateToken(token);
		} catch {
			console.error("Unable to get a new token");
			throw Error("Unable to get a token");
		}
	}, []);

	const onStateChanged = useCallback((state: State) => {
		if (state === "failed") {
			console.log(state);
		} else if (state === "initialized") {
			console.log(state);
		}
		setClientState(state);
	}, []);

	const onConnectionStateChanged = useCallback((state: ConnectionState) => {
		console.log(state);
		setConnectionState(state);
	}, []);


	useEffect(() => {
		if(!client) {
			return;
		}

		client.on("connectionStateChanged", onConnectionStateChanged);
		client.on("stateChanged", onStateChanged);
		client.on("tokenAboutToExpire", onTokenAboutToExpire);
		client.on("tokenExpired", onTokenAboutToExpire);

		return () => {
			client?.off("connectionStateChanged", onConnectionStateChanged);
			client?.off("stateChanged", onStateChanged);
			client?.off("tokenAboutToExpire", onTokenAboutToExpire);
			client?.off("tokenExpired", onTokenAboutToExpire);
		}
	}, [client]);

	return { client, clientState, clientConnectionState };
}