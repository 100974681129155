import { Conversation, Message, MessageUpdateReason } from "@twilio/conversations";
import { useCallback, useEffect, useState } from "react";
import { Chat } from "@twilio-paste/core";
import { MessageFactory } from "../../../components/conversation/MessageFactory";
import { useIframeMessage } from "../../../hooks/useIframeMessage";

export interface useConversationEventsStateHook {
	conversation: Conversation | undefined;
    messages: Message[];
	pasteMessages: Chat[];
	sendMessage: (payload: { body: string }) => Promise<void>;
}

export const useConversationEvents = ({ conversation }: { conversation?: Conversation }): useConversationEventsStateHook => {
	const [messages, setMessages] = useState<Message[]>([]);
	const [pasteMessages, setPasteMessages] = useState<Chat[]>([]);
	const { PostMessageToParent } = useIframeMessage();

	const onMessageAdded = useCallback((message: Message) => {
		setMessages([...messages, message]);
		setPasteMessages([...pasteMessages, MessageFactory({ message })])
	}, [messages, pasteMessages]);

	const onMessageUpdated = useCallback(({ message }: { message: Message, updateReasons: MessageUpdateReason[] }) => {
		const index = messages.findIndex(x => x.sid === message.sid);
		if(index !== -1) {
			const newMessages = [...messages.slice(0, index), message, ...messages.slice(index + 1)];
			const newPasteMessages = [...pasteMessages.slice(0, index), MessageFactory({ message }), ...pasteMessages.slice(index + 1)];
			setMessages(newMessages);
			setPasteMessages(newPasteMessages);
		}
	}, [messages, pasteMessages]);

	const getExistingMessages = useCallback(async () => {
		if(!conversation) {
			return;
		}

		try {
			const m = await conversation.getMessages(500);
			setMessages(m.items);
			setPasteMessages(m.items.map(message => MessageFactory({ message })))
		} catch (error) {
			console.error(error);
		}
	}, [ conversation?.sid ]);

	const sendMessage = useCallback(async ({ body }: { body: string }) => {
		if(!conversation) {
			return;
		}

		try {
			await conversation.sendMessage(body);
		} catch (error) {
			console.error(error);
		}
	}, [conversation]);

	useEffect(() => {
		if(!conversation) {
			return;
		}

		PostMessageToParent({
			type: "ConversationChanged",
			data: {
				conversationSid: conversation.sid
			}
		});

		getExistingMessages();
	}, [getExistingMessages]);

	useEffect(() => {
		if(!conversation) {
			return;
		}

		conversation.on("messageAdded", onMessageAdded);
		conversation.on("messageUpdated", onMessageUpdated);

		return () => {
			conversation.off("messageAdded", onMessageAdded);
			conversation.off("messageUpdated", onMessageUpdated);
		}
	}, [conversation?.sid, onMessageAdded, onMessageUpdated]);

	return { conversation, messages, pasteMessages, sendMessage };
}