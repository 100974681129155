import { MouseEvent, useCallback, useState } from "react";
import { useAppContext } from "./useAppContext";
import { useNavigate } from "react-router-dom";

export const useStartNewConversation = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { api } = useAppContext();
	const navigate = useNavigate();

	const onClick = useCallback(async (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		try {
			setIsLoading(true);
			const conversation = await api.createConversation();
			if (conversation.conversationSid) {
				navigate(`conversation/${conversation.conversationSid}`);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}, [api, isLoading]);

	return { onClick, isLoading };
}