import { Profile, useProfile } from "./hooks/useProfile";
import { ReactElement, createContext, useEffect, useState } from "react";
import { UseToasterReturnedProps, useToaster } from "@twilio-paste/core/toast";
import { useApi, useApiStateHook } from "./hooks/useApi";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useSidebarState, useSidebarStateHook } from "./hooks/useSidebarState";
import { EntraTokenRequest } from "@ciptex/dfa-moneypenny-sdk";
import { PublicAppConfig } from "../../constants";
import { useAuthenticate } from "./hooks/useAuthenticate";
import { useConfig } from "./hooks/useConfig";
import { useIframeMessageEvents } from "./hooks/useIframeMessageEvents";

export type Auth = {
	accountSid: string;
	flexToken?: string;
	token?: string;
	entraToken?: string;
	identity: string;
	configSid: string;
	name?: string;
}

export type StateContextType = {
	sidebar: useSidebarStateHook;
	toaster: UseToasterReturnedProps;
	config: PublicAppConfig;
	api: useApiStateHook;
	isAuthenticated: boolean;
	auth: Auth | undefined;
	profile: Profile | undefined;
	activeConversationSid: string | undefined;
	singleMode: boolean;
	EntraAuthenticate: (data: EntraTokenRequest & { name?: string }) => Promise<void>;
}

export const AppContext = createContext<StateContextType>(null!);

export const AppProvider = ({ children }: { children: ReactElement }) => {
	const location = useLocation();
	const { conversationSid: activeConversationSid } = useParams();
	const [urlParams] = useSearchParams();
	const [singleMode, setSingleMode] = useState<boolean>(false);
	const sidebar = useSidebarState({ enable: !singleMode });
	const toaster = useToaster();
	const { config } = useConfig();
	const { auth, isAuthenticated, EntraAuthenticate, FlexAuthenticate } = useAuthenticate();
	const profile = useProfile({ auth });
	const api = useApi({ auth });
	useIframeMessageEvents({ config, isAuthenticated, FlexAuthenticate, api });

	useEffect(() => {
		if(urlParams.get("singlemode") === "true") {
			setSingleMode(true);
		}
	}, []);

	useEffect(() => {
		console.log("URL CHANGED", location);
	}, [location]);

	return <AppContext.Provider value={{ EntraAuthenticate, singleMode, profile, auth, config, isAuthenticated, toaster, sidebar, api, activeConversationSid }}>{children}</AppContext.Provider>;
}
