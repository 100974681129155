import { PASTE_ELEMENTS, PASTE_THEME } from "./constants";
import { CustomizationProvider } from "@twilio-paste/core/customization";
import { Logger } from "@ciptex-race/logger";
import { Router } from "./Router";
import { createRoot } from "react-dom/client";

const root = createRoot(
	document.getElementById("root") as HTMLElement
);

const getPreferredScheme = () => window?.matchMedia?.("(prefers-color-scheme:dark)")?.matches ? "dark" : "light";
Logger.setOptions({
	appPrefix: ["AI"],
	appPrefixColor: getPreferredScheme() === "light" ? "green" : "pink"
});

root.render(
	<CustomizationProvider baseTheme="default" theme={PASTE_THEME} elements={PASTE_ELEMENTS}>
		<Router />
	</CustomizationProvider>
);
