import { Sidebar, SidebarBody, SidebarCollapseButton, SidebarFooter, SidebarHeader, SidebarHeaderLabel, SidebarPushContentWrapper } from "@twilio-paste/core/sidebar";
import { Box } from "@twilio-paste/core/box";
import { ReactElement } from "react";
import { SidebarAppNavigation } from "./SidebarAppNavigation";
// import { SidebarLogoutButton } from "./SidebarLogoutButton";
import { useAppContext } from "../../hooks/useAppContext";

export const SidebarWrapper = ({ children, mainContentID, topbarID }: { children: ReactElement | ReactElement[], mainContentID: string, topbarID: string }) => {
	const { sidebar, config } = useAppContext();

	return (<>
		{/**
       * No judgement zone
       * To successfully handle a single sidebar that is responsive but also adaptive in it's state handling
       * We need to handle initial render for both mobile and desktop views because the sidebar has inverse initial state
       * - On mobile, the sidebar should start collapsed
       * - On desktop, the sidebar should start expanded
       * We then do a little trickery with opacity and responsive values to stop you seeing the state change flash
       * when it's not mounted we use a transparent sidebar on small screens, after it's mounted we switch to visible,
       * but transition it and delay the transistion start. For desktop, we start visible and never transition.
       */}
		{sidebar.isEnabled && <Box opacity={[!sidebar.isMounted ? "0" : "1", "1"]} transitionDelay="50ms" transition="opacity 150ms ease">
			<Sidebar
				element="SIDEBAR_WIDE"
				variant="compact"
				collapsed={sidebar.isCollapsed}
				mainContentSkipLinkID={mainContentID}
				sidebarNavigationSkipLinkID={sidebar.sidebarNaviationId}
				topbarSkipLinkID={topbarID}
				id={sidebar.sidebarId}
			>
				<SidebarHeader>
					<img src={config.branding?.iconUrl} width="34px" alt={config.strings?.appName} />
					<SidebarHeaderLabel>{config.strings?.appName}</SidebarHeaderLabel>
				</SidebarHeader>
				<SidebarBody>
					<SidebarAppNavigation />
				</SidebarBody>
				<SidebarFooter>
					{/* <SidebarLogoutButton /> */}
					<SidebarCollapseButton
						i18nCollapseLabel="Close sidebar"
						i18nExpandLabel="Open sidebar"
						onClick={() => sidebar.setIsCollapsed(!sidebar.isCollapsed)}
					/>
				</SidebarFooter>
			</Sidebar>
		</Box>}
		{sidebar.isEnabled ? <SidebarPushContentWrapper collapsed={sidebar.isCollapsed} variant="compact">
			{children}
		</SidebarPushContentWrapper> : children}
	</>);
}