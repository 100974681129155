import { Button } from "@twilio-paste/core/button";
import { ThumbsDownIcon } from "@twilio-paste/icons/esm/ThumbsDownIcon";
import { useCallback } from "react"


export const DislikeButton = ({ rating, onClick }: { rating?: -1 | 0 | 1, onClick: (rating: -1 | 0 | 1) => Promise<void> }) => {
	const onClickL = useCallback(() => {
		if(rating === -1) {
			onClick && onClick(0)
		} else {
			onClick && onClick(-1)
		}
	}, [ rating, onClick ]);

	return (<Button
		variant={rating === -1 ? "destructive_icon" : "secondary_icon"}
		size="reset"
		onClick={onClickL}
	>
		<ThumbsDownIcon decorative={false} title="Dislike" />
	</Button>);
}