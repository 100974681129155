import { useCallback, useEffect } from "react";
import { useIsAuthenticated as useIsMsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { useAppContext } from "./useAppContext";

export const useEntraAuth = () => {
	const { config, isAuthenticated, EntraAuthenticate } = useAppContext();
	const { instance, inProgress } = useMsal();
	const isMsAuthenticated = useIsMsAuthenticated();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const Logout = useCallback(async () => {
		try {
			await instance.initialize();
			await instance.logout();
		} catch (error: any) {
			console.error(error);
		}
	}, [instance]);

	const InteractiveAuthenticate = useCallback(async () => {
		if (window.self === window.top) {
			await instance.loginRedirect({
				scopes: ["profile", "User.Read"]
			});
		} else {
			const token = await instance.acquireTokenPopup({
				scopes: ["profile", "User.Read"]
			});

			console.log("POPUP COMPLETE!!!");
			EntraAuthenticate({
				accountSid: config.accountSid,
				configSid: config.configSid,
				entraToken: token.accessToken,
				name: token.account.name
			});

		}
	}, [instance, config]);

	const Authenticate = useCallback(async () => {
		if (isAuthenticated) {
			const { redirectUri, ...rest } = Object.fromEntries(searchParams.entries());
			const url = queryString.stringifyUrl({
				url: redirectUri || "/ai",
				query: rest
			});

			navigate(url);
			return;
		}

		if (!isMsAuthenticated) {
			try {
				await instance.initialize();
			} catch (error) {
				console.error(error);
				return;
			}

			try {
				const token = await instance.ssoSilent({
					scopes: ["profile", "User.Read"]
				});

				EntraAuthenticate({
					accountSid: config.accountSid,
					configSid: config.configSid,
					entraToken: token.accessToken,
					name: token.account.name
				});
			} catch (error) {
				console.warn(error);
				await InteractiveAuthenticate();
			}
		}
	}, [InteractiveAuthenticate, inProgress, config, isAuthenticated, searchParams, isMsAuthenticated]);

	const RedirectAuthenticate = useCallback(async (code: string) => {
		try {
			await instance.initialize();
		} catch (error) {
			console.error(error);
			return;
		}

		try {
			const token = await instance.acquireTokenByCode({
				code: code,
				redirectUri: "/auth"
			});

			EntraAuthenticate({
				accountSid: config.accountSid,
				configSid: config.configSid,
				entraToken: token.accessToken,
				name: token.account.name
			});
		} catch (error) {
			console.error(error);
		}

	}, [config, instance]);

	useEffect(() => {
		if (isAuthenticated) {
			Authenticate();
		}
	}, [isAuthenticated])

	return { RedirectAuthenticate, Authenticate, Logout };
}