
import queryString from "query-string";
import { useEffect } from "react";
import { useEntraAuth } from "../hooks/useEntraAuth";
import { useLocation } from "react-router-dom";

export const EntraRedirect = () => {
	const { hash } = useLocation();
	const { Authenticate } = useEntraAuth();

	useEffect(() => {
		const { code } = queryString.parse(hash);
		if(code && typeof code === "string") {
			Authenticate();
		}
	}, []);

	return <></>;
}