import { MouseEvent, useCallback } from "react";
import { SidebarNavigationItem, SidebarNavigationItemProps } from "@twilio-paste/core/sidebar";
import { useHref, useLinkClickHandler } from "react-router-dom";
import { HistoryIcon } from "@twilio-paste/icons/esm/HistoryIcon";

export const SidebarNavItem = ({ href, children, ...rest }: SidebarNavigationItemProps & { href: string }) => {
	const newHref: string = useHref(href);
	const handleClick = useLinkClickHandler<HTMLAnchorElement>(href);

	const onClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		handleClick(e);
	}, [handleClick, newHref]);

	return (
		<SidebarNavigationItem href={newHref} onClick={onClick} icon={<HistoryIcon decorative />} {...rest}>
			{children}
		</SidebarNavigationItem>
	)
}