import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useUID } from "@twilio-paste/core/dist/uid-library";

export interface useSidebarStateHook {
	sidebarId: string;
	sidebarNaviationId: string;
	isMounted: boolean;
	isEnabled: boolean;
	isCollapsed: boolean;
	setIsCollapsed: Dispatch<SetStateAction<boolean>>;
}

export const useSidebarState = ({ enable }: { enable: boolean }): useSidebarStateHook => {
	const [isMounted, setIsMounted] = useState<boolean>(false);
	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const [isEnabled, setIsEnabled] = useState<boolean>(false);
	const sidebarId = useUID();
	const sidebarNaviationId = useUID();

	useEffect(() => {
		setIsMounted(true);
		setIsEnabled(enable);
	}, [enable]);

	return {
		sidebarId,
		sidebarNaviationId,
		isMounted,
		isEnabled,
		isCollapsed,
		setIsCollapsed
	}
}