import { useCallback, useState } from "react";
import { ArchiveIcon } from "@twilio-paste/icons/esm/ArchiveIcon";
import { Button } from "@twilio-paste/core/button";
import { Conversation } from "@ciptex/dfa-moneypenny-sdk";
import { useAppContext } from "../../hooks/useAppContext";
import { useNavigate } from "react-router-dom";

export const SidebarArchiveButton = ({ item }: { item: Conversation }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { api, activeConversationSid } = useAppContext();
	const navigate = useNavigate();

	const onClick = useCallback(async () => {
		try {
			setIsLoading(true);
			await api.archiveConversation(item.conversationSid);
			if(activeConversationSid === item.conversationSid) {
				navigate("/ai");
			}
		} catch (error: any) {
			console.error(error);
			setIsLoading(false);
		}
	}, [api, activeConversationSid]);

	return (<Button loading={isLoading} variant="inverse" size="icon_small" onClick={onClick}>
		<ArchiveIcon title="Archive Chat" decorative={false} />
	</Button>)
}