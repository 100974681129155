import type { AppConfig } from "@ciptex/dfa-moneypenny-sdk";
import { GenericThemeShape } from "@twilio-paste/core/theme";
import { PasteCustomCSS } from "@twilio-paste/core/customization";

export type PublicAppConfig = Omit<AppConfig, "twilioConversationServiceSid" | "raceAIEntitySid" | "name" | "meta">;

export const STATIC_CONFIG: PublicAppConfig = {
	accountSid: process.env.REACT_APP_ACCOUNT_SID || "",
	configSid: process.env.REACT_APP_CONFIG_SID || ""
}

export const PASTE_ELEMENTS: { [key: string]: PasteCustomCSS } = {
	"SIDEBAR_SKELETON": {
		backgroundColor: "colorBackgroundInverseStrong",
		borderRadius: "borderRadius20",
		marginBottom: "space30",
		height: "44px"
	}
}

export const PASTE_THEME: Partial<GenericThemeShape> = {
	breakpoints: {
		xs: "320px",
		sm: "576px",
		md: "768px",
		lg: "992px",
		xl: "1200px"
	}
}