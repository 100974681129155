import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button"
import { Heading } from "@twilio-paste/core/heading";
import { Link } from "react-router-dom";
import { Paragraph } from "@twilio-paste/core/paragraph";
import { Spinner } from "@twilio-paste/core/spinner";
import { useAppContext } from "../hooks/useAppContext";
import { useEffect } from "react";
import { useEntraAuth } from "../hooks/useEntraAuth";

export const Logout = () => {
	const { Logout: EntraLogout } = useEntraAuth();
	const { config } = useAppContext();

	useEffect(() => {
		EntraLogout();
		sessionStorage.clear();
	}, []);

	return (<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" width="100vw" textAlign="center">
		<Box display="flex" alignItems="center" justifyContent="center" columnGap="space30" >
			<Box marginBottom="space60">
				<img src={config.branding?.iconUrl} width="50px" alt={config.strings?.appName} />
			</Box>
			<Heading as="h2" variant="heading20">
				{config.strings?.appName}
			</Heading>
		</Box>
		<Paragraph>We are logging you out ...</Paragraph>
		<Spinner decorative={false} title="Logout in progress" size="sizeIcon100" />
		<Box marginTop="space80">
			<Link to="/auth">
				<Button variant="secondary">Login</Button>
			</Link>
		</Box>
	</Box>);
}