import { useCallback, useEffect, useState } from "react";
import { Auth } from "../AppProvider";

export interface Profile {
    photoUrl?: string;
}

export const useProfile = ({ auth }: { auth?: Auth }) => {
	const [profile, setProfile] = useState<Profile>();

	const getEntraPhoto = useCallback(async ({ entraToken }: { entraToken: string }) => {
		const photoResponse = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
			method: "GET",
			headers: {
				Authorization: `Bearer ${entraToken}`
			}
		});

		if(photoResponse.ok) {
			const photoBlob = await photoResponse.blob();
			return URL.createObjectURL(photoBlob);
		}
	}, []);

	const getEntraProfile = useCallback(async () => {
		if(!auth?.entraToken) {
			return;
		}

		try {
			const photoUrl = await getEntraPhoto({ entraToken: auth.entraToken });
			setProfile({ photoUrl });
		} catch (error) {
			console.error(error);
		}
	}, [auth]);

	useEffect(() => {
		getEntraProfile();
	}, [getEntraProfile]);

	return profile;
}