import { UserDialog, UserDialogContainer, UserDialogList, UserDialogUserEmail, UserDialogUserInfo, UserDialogUserName, useUserDialogListState } from "@twilio-paste/core/user-dialog"
import { UserDialogMenuLogout } from "./UserDialogMenuLogout";
import { useAppContext } from "../../hooks/useAppContext";

export const UserDialogMenu = () => {
	const { profile, auth } = useAppContext();
	const userDialogList = useUserDialogListState();

	return (auth ? <UserDialogContainer name={auth.name || auth.identity} src={profile?.photoUrl} baseId="i-am-user-dialog">
		<UserDialog aria-label="user menu">
			<UserDialogUserInfo>
				{auth.name && <UserDialogUserName>{auth.name}</UserDialogUserName>}
				<UserDialogUserEmail>{auth.identity}</UserDialogUserEmail>
			</UserDialogUserInfo>
			<UserDialogList {...userDialogList} aria-label="User menu actions">
				<UserDialogMenuLogout {...userDialogList} />
			</UserDialogList>
		</UserDialog>
	</UserDialogContainer> : <></>);
}