import { DfaMoneypennyClient, EntraTokenRequest } from "@ciptex/dfa-moneypenny-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Auth } from "../AppProvider";
import { useIframeMessage } from "../../../hooks/useIframeMessage";
import { useNavigate } from "react-router-dom";

export const useAuthenticate = () => {
	const [auth, setAuth] = useState<Auth>();
	const { PostMessageToParent } = useIframeMessage();
	const navigate = useNavigate();

	const isAuthenticated = useMemo(() => {
		if(auth && auth.token && auth.token !== "") {
			return true;
		}

		return false;
	}, [ auth ]);

	const FlexAuthenticate = useCallback(async (data: Auth) => {
		if (data.flexToken && !data.token) {
			try {
				const client = new DfaMoneypennyClient({ accountSid: data.accountSid, token: data.flexToken });
				const { token } = await client.token.create({ identity: data.identity }, { configSid: data.configSid });
				const res = { ...data, token }
				sessionStorage.setItem("t", JSON.stringify(res));
				setAuth(res);
			} catch (error) {
				console.error(error);
				navigate("/auth");
			}
		}
	}, []);

	const EntraAuthenticate = useCallback(async (data: (EntraTokenRequest & { name?: string })) => {
		const client = new DfaMoneypennyClient();
		const { token, identity } = await client.token.create1(data, {});
		const res = {
			token,
			identity,
			accountSid: data.accountSid,
			configSid: data.configSid,
			entraToken: data.entraToken,
			name: data.name
		};

		sessionStorage.setItem("t", JSON.stringify(res));
		setAuth(res);
	}, []);

	useEffect(() => {
		const t = sessionStorage.getItem("t");
		if(t) {
			setAuth(JSON.parse(t));
		}
	}, []);

	useEffect(() => {
		if(auth?.token) {
			PostMessageToParent({ type: "Ready", data: {} });
		}
	}, [auth]);

	return { auth, isAuthenticated, FlexAuthenticate, EntraAuthenticate };
}