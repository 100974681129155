import { useCallback, useEffect } from "react";
import type { Auth } from "../AppProvider";
import type { PublicAppConfig } from "../../../constants";
import { useApiStateHook } from "./useApi";
import { useNavigate } from "react-router-dom";

interface Props {
    config: PublicAppConfig;
    FlexAuthenticate: (data: Auth) => Promise<void>;
    isAuthenticated: boolean;
    api: useApiStateHook;
}

export const useIframeMessageEvents = ({ api, config, isAuthenticated, FlexAuthenticate }: Props) => {
	const navigate = useNavigate();

	const ChangeConversation = useCallback(({ conversationSid }: { conversationSid: string }) => {
		if (!isAuthenticated) {
			console.warn("Change Conversation Blocked due to unAuthenticated");
			return;
		}

		console.log("Changing Conversation to ", conversationSid);
		navigate(`/ai/conversation/${conversationSid}`);
	}, [isAuthenticated]);

	const onMessage = useCallback(async (event: any) => {
		if (!config.iframe?.allowedOrigins.includes(event.origin)) {
			console.warn("Blocked Event", event, config.iframe?.allowedOrigins);
			return;
		}

		console.log("Allowed Event", event);

		try {
			switch (event.data.type) {
				case "FlexAuthenticate":
					await FlexAuthenticate(event.data.data);
					break;
				case "ChangeConversation":
					ChangeConversation(event.data.data);
					break;
				case "ArchiveConversation":
					if(event.data?.data?.conversationSid) {
						await api.archiveConversation(event.data.data.conversationSid);
					}
					break;
				default:
					break;
			}
		} catch (error) {
			console.error(error);
		}
	}, [FlexAuthenticate, ChangeConversation, api, config]);

	useEffect(() => {
		if (window.self === window.top) {
			return;
		}

		window.addEventListener("message", onMessage);
		return () => {
			window.removeEventListener("message", onMessage);
		}
	}, [onMessage]);
}