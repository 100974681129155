import { useCallback, useEffect, useState } from "react";
import { Conversation } from "@twilio/conversations";
import { useClientEventsStateHook } from "./useClientEvents";
import { useNavigate } from "react-router-dom";

interface Props {
    activeConversationSid: string | undefined;
    conversationClient: useClientEventsStateHook;
}

export const useConversation = ({ conversationClient, activeConversationSid }: Props) => {
	const [conversation, setConversation] = useState<Conversation>();
	const navigate = useNavigate();

	const getConversation = useCallback(async () => {
		if (conversationClient.clientState === "initialized" && conversationClient.clientConnectionState === "connected" && conversationClient.client && activeConversationSid) {
			try {
				const c = await conversationClient.client.getConversationBySid(activeConversationSid);
				setConversation(c);
			} catch (error) {
				console.error("Failed to fetch conversation:", error);
				navigate("/ai");
			}
		}
	}, [conversationClient, activeConversationSid]);

	useEffect(() => {
		getConversation();
	}, [getConversation]);

	return conversation;
}