
import { ReactElement, createContext } from "react";
import { useClientEvents, useClientEventsStateHook } from "./hooks/useClientEvents";
import { useConversationEvents, useConversationEventsStateHook } from "./hooks/useConversationEvents";
import { useAppContext } from "../../hooks/useAppContext";
import { useClient } from "./hooks/useClient";
import { useConversation } from "./hooks/useConversation";

export type ConversationContextType = {
  conversation: useConversationEventsStateHook;
  client: useClientEventsStateHook;
};

export const ConversationContext = createContext<ConversationContextType>(null!);

export const ConversationProvider = ({ children }: { children: ReactElement }) => {
	const { auth, activeConversationSid } = useAppContext();

	const client = useClientEvents({
		client: useClient({ auth })
	});

	const conversation = useConversationEvents({
		conversation: useConversation({ conversationClient: client, activeConversationSid })
	});

	return <ConversationContext.Provider value={{ client, conversation }}>{children}</ConversationContext.Provider>;
};