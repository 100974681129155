import { useCallback, useState } from "react";
import { Button } from "@twilio-paste/core/button";
import { InformationIcon } from "@twilio-paste/icons/esm/InformationIcon";
import { Tooltip } from "@twilio-paste/core";

interface SourceButtonProps {
    sources: string[];
}

export const SourceButton = ({ sources }: SourceButtonProps) => {
	const [showSourcePopup, setShowSourcePopup] = useState(false);

	const onClick = useCallback(() => {
		setShowSourcePopup(true);
		setTimeout(() => {
			setShowSourcePopup(false);
		}, 2000);
	}, []);

	return (
		<Tooltip visible={showSourcePopup} text={sources.toString()} placement="top">
			<Button variant="secondary_icon" size="reset" onClick={onClick}>
				<InformationIcon decorative={false} title="Source" />
			</Button>
		</Tooltip>
	);
};