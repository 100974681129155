import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { App } from "./App";
import { AuthApp } from "./components/AuthApp";
import { ConversationApp } from "./components/ConversationApp";
import { EntraRedirect } from "./pages/EntraRedirect";
import { Loading } from "./components/Loading";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		children: [
			{
				path: "/ai",
				element: <ConversationApp />,
				children: [
					{
						index: true,
						async lazy() {
							const { NoConversation } = await import("./pages/NoConversation");
							return { Component: NoConversation };
						}
					},
					{
						path: "conversation/:conversationSid",
						async lazy() {
							const { Conversation } = await import("./pages/Conversation");
							return { Component: Conversation };
						}
					},
					{
						path: "*",
						element: <Navigate to="/ai" replace />
					}
				]
			},
			{
				path: "auth",
				element: <AuthApp />,
				children: [
					{
						index: true,
						element: <Login />
					},
					{
						path: "entra-redirect",
						element: <EntraRedirect />
					},
					{
						path: "logout",
						element: <Logout />
					}
				]
			},
			{
				path: "*",
				element: <Navigate to="/ai" replace />
			}
		]
	},
	{
		index: true,
		element: <Navigate to="/ai" replace />
	}
]);

export const Router = () => {
	return <RouterProvider router={router} fallbackElement={<Loading />} />
}