import { useCallback, useMemo } from "react";
import { Box } from "@twilio-paste/core/box";
import { ButtonGroup } from "@twilio-paste/core/button-group";
import { CopyButton } from "./CopyButton";
import { DislikeButton } from "./DislikeButton";
import { LikeButton } from "./LikeButton";
import { Message } from "@twilio/conversations";
import { SourceButton } from "./SourceButton";

export const MessageActions = ({ message }: { message: Message }) => {
	const attributes = useMemo(() => {
		return message.attributes as Record<string, any>;
	}, [ message.attributes ]);

	const onRateMessage = useCallback(async (rating: -1 | 0 | 1) => {
		try {
			await message.updateAttributes({
				...attributes,
				rating
			});
		} catch (error: any) {
			console.error(error);
		}
	}, [message, attributes]);

	return (<Box marginTop="space20" display="flex" justifyContent="center" width="150px" borderRadius="borderRadiusPill" backgroundColor="colorBackgroundBody" paddingY="space30" paddingX="space40">
		<ButtonGroup>
			<LikeButton rating={attributes.rating} onClick={onRateMessage} />
			<DislikeButton rating={attributes.rating} onClick={onRateMessage} />
			<CopyButton text={message.body} />
			{(attributes.sources?.length > 0) ? <SourceButton sources={attributes.sources} /> : <></>}
		</ButtonGroup>
	</Box>);
}