import { Loading } from "../components/Loading";
import { useAppContext } from "../hooks/useAppContext";
import { useEffect } from "react";
import { useEntraAuth } from "../hooks/useEntraAuth";

export const Login = () => {
	const { Authenticate } = useEntraAuth();
	const { config } = useAppContext();

	useEffect(() => {
		console.log("AUTHENTICATING")
		Authenticate();
	}, []);

	return (<Loading config={config} />);
}