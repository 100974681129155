import { Box, BoxProps } from "@twilio-paste/core/box";

export const OutletWrapper = ({ children, ...props }: BoxProps) => {
	return (<Box
		as="main"
		backgroundColor="colorBackground"
		position="relative"
		display="flex"
		flex="1 1 auto"
		width="100%"
		overflow="hidden"
		{...props}
	>
		{children}
	</Box>)
}