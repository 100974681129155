import { Topbar, TopbarActions } from "@twilio-paste/core/topbar";
import { Box } from "@twilio-paste/core/box";
import { UserDialogMenu } from "./UserDialogMenu";
import { useAppContext } from "../../hooks/useAppContext";

export const AppTopbar = ({ id }: { id: string }) => {
	const { sidebar } = useAppContext();
	return (<Box minWidth="size70">
		{sidebar.isEnabled && <Topbar id={id}>
			<TopbarActions justify="end">
				<UserDialogMenu />
			</TopbarActions>
		</Topbar>}
	</Box>)
}