import { useCallback, useState } from "react";
import { Button } from "@twilio-paste/core/button";
import { CopyIcon } from "@twilio-paste/icons/esm/CopyIcon";
import { Tooltip } from "@twilio-paste/core";

export const CopyButton = ({ text }: { text: string | null }) => {
	const [showCopiedPopup, setShowCopiedPopup] = useState(false);

	const onClick = useCallback(() => {
		if (text) {
			navigator.clipboard.writeText(text);
			setShowCopiedPopup(true);
			setTimeout(() => {
				setShowCopiedPopup(false);
			}, 2000);
		}
	}, [text]);

	return (
		<Tooltip visible={showCopiedPopup} text="Copied!" placement="top">
			<Button variant="secondary_icon" size="reset" onClick={onClick}>
				<CopyIcon decorative={false} title="Copy" />
			</Button>
		</Tooltip>
	);
};