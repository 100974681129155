import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { useCallback, useMemo } from "react";
import { useAppContext } from "./useAppContext";

export const useEntraApplication = () => {
	const { config } = useAppContext();

	const EntraLogger = useCallback((level:LogLevel, message:any, containsPii:boolean) => {
		if (containsPii) {
			return;
		}
		switch (level) {
			case LogLevel.Error:
				console.error(message);
				return;
			case LogLevel.Info:
				console.info(message);
				return;
			case LogLevel.Verbose:
				console.debug(message);
				return;
			case LogLevel.Warning:
				console.warn(message);
				return;
			default:
				return;
		}
	}, []);

	const application = useMemo(() => {
		if(!config?.entraAuth) {
			return;
		}

		return new PublicClientApplication({
			auth: {
				...config.entraAuth,
				redirectUri: `${window.location.protocol}//${window.location.host}/auth/entra-redirect`
			},
			cache: {
				cacheLocation: "sessionStorage", // This configures where your cache will be stored
				storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
			},
			system: {
				loggerOptions: {
					loggerCallback: EntraLogger
				}
			}
		});
	}, [config, EntraLogger]);

	return application;
}