// MessageFactory.tsx
import { Message as ConversationsMessage } from "@twilio/conversations";
import { Message } from "../message/Message";
import { MessageVariants } from "@twilio-paste/core/chat-log";

export const MessageFactory = ({ message }: { message: ConversationsMessage }) => {
	const variant: MessageVariants = message.author === "AI-Assistant" ? "inbound" : "outbound";

	return {
		id: message.sid,
		variant,
		content: (<Message message={message} variant={variant} />)
	};
};