import { ChatMessage, ChatMessageMeta, ChatMessageMetaItem, MessageVariants, ChatBubble as TwilioChatBubble } from "@twilio-paste/core/chat-log";
import { Avatar } from "@twilio-paste/core/avatar";
import { Message as ConversationsMessage } from "@twilio/conversations";
import { MessageActions } from "../message-actions/MessageActions";
import { useAppContext } from "../../hooks/useAppContext";
import { useMemo } from "react";

export const Message = ({ variant, message }: { variant: MessageVariants, message: ConversationsMessage }) => {
	const { config, auth, profile } = useAppContext();

	const dateCreated = useMemo(() => {
		return message.dateCreated?.toLocaleString("en-GB", {
			hour: "numeric",
			minute: "numeric",
			hour12: true
		});
	}, [ message.dateCreated ]);

	const avatarSrc = useMemo(() => {
		return variant === "inbound" ? config.branding?.iconUrl : profile?.photoUrl
	}, [variant, config]);

	const name = useMemo(() => {
		if(message.author && (message.author === auth?.identity)) {
			return auth.name || message.author;
		}
		else if(message.author) {
			return message.author;
		}
		else {
			return "";
		}
	}, [message, auth]);

	return (<ChatMessage variant={variant}>
		<TwilioChatBubble>{message.body}</TwilioChatBubble>
		<ChatMessageMeta aria-label={`Message sent by ${name} on ${dateCreated}`}>
			<ChatMessageMetaItem>
				<Avatar
					name={name}
					src={avatarSrc}
					size={"sizeIcon20"}
				/>
				{name} ・ {dateCreated}
			</ChatMessageMetaItem>
		</ChatMessageMeta>
		{variant === "inbound" && <MessageActions message={message} />}
	</ChatMessage>);
}