import { useCallback, useEffect, useState } from "react";
import { Auth } from "../../AppProvider/AppProvider";
import { Client } from "@twilio/conversations";

export const useClient = ({ auth }: { auth?: Auth }) => {
	const [client, setClient] = useState<Client>();

	const getClient = useCallback(async () => {
		if (!auth?.token) {
			return;
		}

		setClient(new Client(auth.token));
	}, [auth]);

	useEffect(() => {
		getClient();
	}, [getClient]);

	return client;
}