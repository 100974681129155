import { SidebarNavigation, SidebarNavigationSeparator } from "@twilio-paste/core/sidebar";
import { SidebarArchiveButton } from "./SidebarArchiveButton";
import { SidebarNavItem } from "./SidebarNavItem";
import { SidebarNewChatButton } from "./SidebarNewChatButton";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";
import { useAppContext } from "../../hooks/useAppContext";
import { useMemo } from "react";

export const SidebarAppNavigation = () => {
	const { api, activeConversationSid } = useAppContext();
	const { isLoading } = api;

	const items = useMemo(() => {
		return api.conversations || [];
	}, [api]);

	return (<SidebarNavigation aria-label="main">
		<SidebarNewChatButton />
		<SidebarNavigationSeparator />
		{isLoading ? ([...Array(2)].map((_, index) => (<SkeletonLoader element="SIDEBAR_SKELETON" key={index} />))) : (
		// Render chat items when loaded
			items.map((item) => (
				<SidebarNavItem
					selected={activeConversationSid === item.conversationSid}
					key={item.conversationSid}
					href={`conversation/${item.conversationSid}`}
				>
					{item.friendlyName}
					<SidebarArchiveButton item={item} />
				</SidebarNavItem>
			))
		)}
	</SidebarNavigation>);
};