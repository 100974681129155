import { MouseEvent, useCallback } from "react";
import { useHref, useLinkClickHandler } from "react-router-dom";
import { LogOutIcon } from "@twilio-paste/icons/esm/LogOutIcon";
import { UserDialogListItem } from "@twilio-paste/core/user-dialog";

export const UserDialogMenuLogout = (props: any) => {
	const newHref: string = useHref("/auth/logout");
	const handleClick = useLinkClickHandler<HTMLAnchorElement>("/auth/logout");

	const onClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		handleClick(e);
	}, [handleClick, newHref]);

	return (<UserDialogListItem {...props} href={newHref} onSelect={onClick} >
		<LogOutIcon decorative />
		Logout
	</UserDialogListItem>)
}