import { useCallback } from "react";

export const useIframeMessage = () => {
	const PostMessageToParent = useCallback(({ type, data } : { type: string, data: Record<string, any>}) => {
		if(window.self === window.top) {
			return;
		}

		console.log("Posting Message to Parent", { type, data });
		window.parent?.postMessage({
			source: window.location.host,
			type: type,
			data: data
		}, "*");
	}, [window.location]);

	return { PostMessageToParent };
}