import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { useAppContext } from "../../hooks/useAppContext";
import { useStartNewConversation } from "../../hooks/useStartNewConversation";

export const SidebarNewChatButton = () => {
	const { onClick, isLoading } = useStartNewConversation();
	const { sidebar } = useAppContext();

	return (
		<Box width="100%" marginBottom="space50">
			<Button
				variant="inverse"
				onClick={onClick}
				loading={isLoading}
				fullWidth={sidebar.isCollapsed ? false : true}>
				{sidebar.isCollapsed ? <PlusIcon decorative={false} title="Start a New Chat" /> : "Start a New Chat"}
			</Button>
		</Box>
	);
};