import { Box } from "@twilio-paste/core/box";
import { Heading } from "@twilio-paste/core/heading";
import { Paragraph } from "@twilio-paste/core/paragraph";
import { PublicAppConfig } from "../constants";
import { Spinner } from "@twilio-paste/core/spinner";

export const Loading = ({ config }: {config?: PublicAppConfig}) => {
	return (<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" width="100vw" textAlign="center">
		<Box display="flex" alignItems="center" justifyContent="center" columnGap="space30" >
			{config?.branding?.iconUrl && <Box marginBottom="space60">
				<img src={config.branding.iconUrl} width="50px" alt={config.strings?.appName} />
			</Box>}
			{config?.strings?.appName && <Heading as="h2" variant="heading20">
				{config.strings.appName}
			</Heading>}
		</Box>
		<Paragraph>We are logging you in ...</Paragraph>
		<Spinner decorative={false} title="Login in progress" size="sizeIcon100" />
	</Box>);
}